var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Modal from './Modal';
export function initAnchorToModal() {
    const getHTML = (element) => {
        const link = addWordToLinkIfMissing(element.href, 'ajax');
        return fetchHTMLContent(link);
    };
    document.querySelectorAll('a[data-modal="true"]').forEach((anchor) => {
        new Modal(anchor, getHTML);
    });
}
const fetchHTMLContent = (url) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield fetch(url);
    const html = response.text();
    return html;
});
const addWordToLinkIfMissing = (link, wordToAdd) => {
    if (link.includes(wordToAdd))
        return link;
    const halves = link.split('?');
    let firstHalf = halves[0];
    const secondHalf = halves[1];
    if (!firstHalf.endsWith('/'))
        firstHalf += '/';
    firstHalf += wordToAdd;
    const editedLink = [firstHalf, secondHalf].join('?');
    return editedLink;
};
