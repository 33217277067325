var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export default class Modal {
    constructor(element, getHTML, clickableArea) {
        this.element = element;
        this.getHTML = getHTML;
        this.clickableArea = clickableArea;
        let tmpClickArea = clickableArea;
        if (clickableArea === undefined) {
            tmpClickArea = element.querySelectorAll('.personBox__text__name')[0];
        }
        tmpClickArea.classList.add('clickable_area');
        tmpClickArea.addEventListener('mouseenter', () => this.loadData());
        tmpClickArea.addEventListener('click', (event) => {
            event.preventDefault();
            this.open();
        });
        this.modal = this.createModalCard();
        document.body.appendChild(this.modal);
        const modalClose = this.modal.querySelector('.modal__close');
        modalClose.addEventListener('click', () => this.close());
        document.addEventListener('keydown', (event) => {
            if (event.key === 'Escape')
                this.close();
        });
    }
    loadData(cache = true) {
        return __awaiter(this, void 0, void 0, function* () {
            if (cache && this.html)
                return this.html;
            try {
                this.html = yield this.getHTML(this.element);
                return this.html;
            }
            catch (_a) {
                return '';
            }
        });
    }
    open() {
        return __awaiter(this, void 0, void 0, function* () {
            const modalContent = this.modal.querySelector('.modal-content');
            modalContent.innerHTML = yield this.loadData();
            document.body.classList.add('overflow-hidden');
            this.modal.classList.add('active');
            Modal.overlay.classList.add('active');
        });
    }
    close() {
        return __awaiter(this, void 0, void 0, function* () {
            document.body.classList.remove('overflow-hidden');
            this.modal.classList.remove('active');
            Modal.overlay.classList.remove('active');
        });
    }
    createModalCard() {
        const modalCard = document.createElement('div');
        modalCard.classList.add('modal');
        modalCard.classList.add('card__modal');
        modalCard.innerHTML = `
      <button aria-label="Modal zumachen" class="btn-close modal__close">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M18 6l-12 12" />
          <path d="M6 6l12 12" />
        </svg>
      </button>
      <div class="modal-content max-h-full"></div>
    `;
        return modalCard;
    }
    static createOverlay() {
        const overlay = document.createElement('div');
        overlay.classList.add('overlay');
        document.body.appendChild(overlay);
        return overlay;
    }
}
Modal.overlay = document.querySelector('.overlay') || Modal.createOverlay();
