var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import FormErrorController from '../FormErrorController';
export default class FormRequestController {
    constructor(formComplex) {
        this.formComplex = formComplex;
        this.form = formComplex.form;
        this.form.addEventListener('submit', (e) => this.onFormSubmit(e));
    }
    onFormSubmit(e) {
        return __awaiter(this, void 0, void 0, function* () {
            e.preventDefault();
            for (const feature of this.formComplex.features) {
                try {
                    if (!feature.onSubmit)
                        continue;
                    const error = yield feature.onSubmit(e);
                    if (error) {
                        console.error('SchulgeldErmaessigung: onSubmit failed', error);
                        break;
                    }
                }
                catch (e) {
                    console.error('SchulgeldErmaessigung: Feature failed to run onSubmit', e);
                }
            }
            if (FormErrorController.hasErrors()) {
                for (const feature of this.formComplex.features) {
                    if (feature.onSubmitFailed) {
                        feature.onSubmitFailed();
                    }
                }
            }
            else {
                // this.form.submit();
            }
            const formData = new FormData(this.form);
            const response = yield fetch(this.form.action, {
                method: this.form.method,
                body: formData,
            });
            const html = yield response.text();
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, 'text/html');
            const errors = [];
            for (const feature of this.formComplex.features) {
                try {
                    if (feature.onServerResponse) {
                        errors.push(yield feature.onServerResponse(doc));
                    }
                }
                catch (e) {
                    console.error('FormRequestController: Feature failed to run onServerResponse', e);
                }
            }
            if (errors.some((error) => error)) {
                const submitButton = this.form.querySelector('button[type="submit"]');
                const textNode = submitButton.childNodes.item(0);
                if (textNode) {
                    textNode.textContent = 'Erneut senden';
                }
                else {
                    submitButton.textContent = 'Erneut senden';
                }
                submitButton.disabled = false;
            }
            else {
                document.body.innerHTML = doc.body.innerHTML;
            }
        });
    }
}
