import { ManageIban } from './utils/ManageIban';
import { PresetValidityMessage } from './utils/PresetValidityMessage';
import RequireAgeToBeInRange from './utils/RequireAgeToBeInRange';
import RequiredField from './utils/RequiredField';
import SendFormWithServerSideErrorHandling from './utils/SendFormWithServerSideErrorHandling';
import { RadioSelectionClearButton } from './utils/RadioSelectionClearButton';
import FormRequestController from './utils/FormRequestController';
import ValidateFieldsOnChange from './utils/ValidateFieldsOnChange';
import ScrollUpAtSuccessMessage from './utils/ScrollUpAtSuccessMessage';
import AddClassOnSubmit from './utils/AddClassOnSubmit';
import RequireIBANIfAccountHolderSelected from './utils/RequireIBANIfAccountHolderSelected';
export var GeschwisterBonusFormFields;
(function (GeschwisterBonusFormFields) {
    GeschwisterBonusFormFields["CHILD_1_BIRTH_DATE"] = "#geschwisterbonus-1208-date-1";
    GeschwisterBonusFormFields["CHILD_2_BIRTH_DATE"] = "#geschwisterbonus-1208-date-2";
    GeschwisterBonusFormFields["CHILD_3_BIRTH_DATE"] = "#geschwisterbonus-1208-date-3";
    GeschwisterBonusFormFields["CHILD_4_BIRTH_DATE"] = "#geschwisterbonus-1208-date-4";
    GeschwisterBonusFormFields["CHILD_5_BIRTH_DATE"] = "#geschwisterbonus-1208-date-5";
    GeschwisterBonusFormFields["IBAN"] = "#geschwisterbonus-1208-text-27";
    GeschwisterBonusFormFields["CAPTCHA"] = "#geschwisterbonus-1208-captcha-1";
})(GeschwisterBonusFormFields || (GeschwisterBonusFormFields = {}));
export default class GeschwisterBonus {
    constructor() {
        this.SELECTOR = 'geschwisterbonus-1208';
        this.features = [
            new RequireAgeToBeInRange(GeschwisterBonusFormFields.CHILD_1_BIRTH_DATE),
            new RequireAgeToBeInRange(GeschwisterBonusFormFields.CHILD_2_BIRTH_DATE),
            new RequireAgeToBeInRange(GeschwisterBonusFormFields.CHILD_3_BIRTH_DATE),
            new RequireAgeToBeInRange(GeschwisterBonusFormFields.CHILD_4_BIRTH_DATE),
            new RequireAgeToBeInRange(GeschwisterBonusFormFields.CHILD_5_BIRTH_DATE),
            new ManageIban(),
            new RequiredField('#geschwisterbonus-1208-singleselect-1', 'Bitte wählen Sie eine Option aus', { placeholder: 'Bitte Schule wählen' }),
            new RequiredField('#geschwisterbonus-1208-singleselect-2', 'Bitte wählen Sie eine Option aus', { placeholder: 'Bitte Schule wählen' }),
            new PresetValidityMessage('#geschwisterbonus-1208-checkbox-2', 'Bitte akzeptieren Sie die Datenschutzbestimmungen'),
            new ScrollUpAtSuccessMessage('#geschwisterbonus-1208'),
            new RequireIBANIfAccountHolderSelected(GeschwisterBonusFormFields.IBAN, [
                '#geschwisterbonus-1208-radiobutton-2-0',
                '#geschwisterbonus-1208-radiobutton-2-0',
            ]),
            new RadioSelectionClearButton('#geschwisterbonus-1208-radiobutton-2', '#geschwisterbonus-1208-radiobutton-2 .form-radio input', {
                inputsToClearErrorsSelector: GeschwisterBonusFormFields.IBAN,
            }),
            new AddClassOnSubmit('#geschwisterbonus-1208', 'form--submitted'),
            new SendFormWithServerSideErrorHandling(GeschwisterBonusFormFields.CAPTCHA),
            new ValidateFieldsOnChange()
        ];
        for (const feature of this.features) {
            // try catch is important here because we don't want a broken feature to impact the others
            try {
                if (feature.start) {
                    feature.start();
                }
            }
            catch (e) {
                console.error('GeschwisterBonus: Feature failed to start', e);
            }
        }
        const form = document.querySelector(`#${this.SELECTOR}`);
        if (!form)
            throw new Error(`GeschwisterBonus: Form with selector #${this.SELECTOR} not found.`);
        this.form = form;
        if (!form)
            return;
        new FormRequestController(this);
    }
}
