import FormErrorController from '../FormErrorController';
export default class ValidateFieldsOnChange {
    start() {
        const allInputs = document.querySelectorAll('input,select,textarea');
        const allButtons = document.querySelectorAll('button');
        for (const input of allInputs) {
            input.addEventListener('change', () => {
                FormErrorController.update();
            });
        }
        for (const button of allButtons) {
            button.addEventListener('click', () => {
                if (button.type === 'submit')
                    return;
                FormErrorController.update();
            });
        }
    }
}
